import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { config } from "../../lib/utils/config";
import { scrubPath } from "./data-scrubber";

let appInsights: ApplicationInsights | undefined;

export function getAppInsights(): ApplicationInsights | undefined {
  return appInsights;
}

/**
 * Initialize Application Insights
 *
 * This function writes cookies. Call only if user has given consent.
 */
export function initAppInsights() {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.getAppInsightsInstrumentationKey(),
      disableAjaxTracking: true, // Only track calls made via our `fetch` wrapper.
      maxAjaxCallsPerView: -1, // No threshold for number of tracked ajax requests.
    },
  });

  appInsights.loadAppInsights();

  // Adding a data filter to scrub username and app name
  // This will run after the track method is called before the data is sent to the upload queue
  appInsights.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.baseData;
    if (!telemetryItem) {
      return;
    }

    if (telemetryItem.url) {
      telemetryItem.url = scrubPath(telemetryItem.url);
    }

    if (envelope.tags && envelope.tags["ai.operation.name"]) {
      envelope.tags["ai.operation.name"] = scrubPath(envelope.tags["ai.operation.name"]);
    }

    if (telemetryItem.properties && telemetryItem.properties["path"]) {
      telemetryItem.properties["path"] = scrubPath(telemetryItem.properties["path"]);
    }

    // Set global telemetry property
    telemetryItem.properties = telemetryItem.properties || {};
    // Multiple services send data to the same app. Use telemetrySource to tell where it comes from
    telemetryItem.properties["telemetrySource"] = "portal-client";
  });
}
