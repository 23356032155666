import { getAppInsights } from "./app-insights";

export namespace metrics {
  export function emitMetric(name: string, value: number, dimensions?: { [key: string]: string }) {
    const appInsights = getAppInsights();

    if (!appInsights) {
      return;
    }

    appInsights.trackMetric({ name, average: value }, dimensions);
  }
}
